import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  //--------------------------------------
  //微信端路由
  //--------------------------------------
  {
    path:'/',//备案号
    name:'Filing No',
    redirect:'/home',
    component:()=>import('../views/filingno.vue')
  },
  {
    path: '/home',//首页
    name: 'home',
    // redirect:'/home',
    component: () => import('../views/WeChat/home/home.vue'),
    meta:{
      title:'首页'
    }
  },
  {
    path: '/shop',//商城
    name: 'shop',
    component: () => import('../views/WeChat/shop/shop.vue'),
    meta:{
      title:'商城'
    }
  },
  {
    path: '/my',//我的
    name: 'my',
    component: () => import('../views/WeChat/my/my.vue'),
    meta:{
      title:'我的'
    }
  },
  {
    path: '/confirm',//确认订单
    name: 'confirm',
    component: () => import('../views/WeChat/shop/confirm.vue'),
    meta:{
      title:'确认订单'
    }
  },
  {
    path: '/chioce',//选择商品
    name: 'chioce',
    component: () => import('../views/WeChat/shop/choice.vue'),
    meta:{
      title:'选择商品'
    }
  },
  {
    path: '/paysu',//支付成功
    name: 'paysu',
    component: () => import('../views/WeChat/shop/paysu.vue'),
    meta:{
      title:'支付成功'
    }
  },
  {
    path: '/uplode',//上传图片
    name: 'uplode',
    component: () => import('../views/WeChat/shop/uplode.vue'),
    meta:{
      title:'上传图片'
    }
  },
  {
    path: '/footlist',//足迹列表
    name: 'footlist',
    component: () => import('../views/WeChat/home/footlist.vue'),
    meta:{
      title:'足迹列表'
    }
  },
  {
    path: '/turn',//转让
    name: 'turn',
    component: () => import('../views/WeChat/home/turn.vue'),
    meta:{
      title:'选择新的管理'
    }
  },
  {
    path: '/oredlist',//订单列表
    name: 'oredlist',
    component: () => import('../views/WeChat/my/oredlist.vue'),
    meta:{
      title:'订单列表'
    }
  },
  {
    path: '/orddet',//订单详情
    name: 'orddet',
    component: () => import('../views/WeChat/my/orddet.vue'),
    meta:{
      title:'订单详情'
    }
  },
  {
    path: '/orderdet',//锦绣乡土订单详情
    name: 'orderdet',
    component: () => import('../views/WeChat/my/orderdet.vue'),
    meta:{
      title:'订单详情'
    }
  },
  {
    path: '/details',//商品详情
    name: 'details',
    component: () => import('../views/WeChat/shop/details.vue'),
    meta:{
      title:'商品详情'
    }
  },
  {
    path: '/comord',//商城的确认订单
    name: 'comord',
    component: () => import('../views/WeChat/shop/comord.vue'),
    meta:{
      title:'确认订单'
    }
  },
  {
    path: '/ordsuu',//支付成功
    name: 'ordsuu',
    component: () => import('../views/WeChat/shop/ordsuu.vue'),
    meta:{
      title:'支付成功'
    }
  }, 
  {
    path: '/address',//收货地址
    name: 'address',
    component: () => import('../views/WeChat/my/address.vue'),
    meta:{
      title:'收货地址'
    }
  }, 
  {
    path: '/newaddress',//新增地址
    name: 'newaddress',
    component: () => import('../views/WeChat/my/newaddress.vue'),
    meta:{
      title:'新增地址'
    }
  }, 
  {
    path: '/userinfo',//个人信息
    name: 'userinfo',
    component: () => import('../views/WeChat/my/userinfo.vue'),
    meta:{
      title:'个人信息'
    }
  }, 
  {
    path: '/ordformlist',//从订单列表支付
    name: 'ordformlist',
    component: () => import('../views/WeChat/shop/ordformlist.vue'),
    meta:{
      title:'确认订单'
    }
  }, 
  {//论坛
    path:'/forum',
    name:'forun',
    component:()=>import('../views/WeChat/home/forum.vue'),
    meta:{
      title:'诉说乡愁'
    }
  },
  //--------------------------------------
  //节点管理路由
  //--------------------------------------
  {//锦绣乡土节点管理登录
    path:'/nodelogin',
    name:'nodelogin',
    component:()=>import('../views/nodemanage/nodelogin.vue'),
    meta:{
      title:'节点登录'
    }
  },
  {//锦绣乡土节点管理
   path:'/nodemanage',
   name:'nodemanage',
   component:()=>import('../views/nodemanage/nodemanage.vue'),
   meta:{
      title:'节点管理'
   }
  },
  //--------------------------------------
  //后台管理路由
  //--------------------------------------
  {
    path:'/BackStage',
    name:'BackStage',
    component:()=>import('../views/BackStage/home.vue'),
    redirect:'/BackStage/network',
    children:[
      //网点数据
      {path:'/BackStage/network',component:()=>import('../views/BackStage/list/network.vue')},
      //订单列表
      {path:'/BackStage/order',component:()=>import('../views/BackStage/list/order.vue')},
      //网点设置
      {path:'/BackStage/netsetup',component:()=>import('../views/BackStage/list/netsetup.vue')}
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
