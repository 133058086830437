function http(){
    let url=window.location.protocol
   if(url=='https:') {
      return
   }else{
    window.location.protocol='https:'
   }
}
export default{
    http
}