<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100%;
  background: #f6f6f6;
  margin: auto;
}
</style>
<script>
import http from '@/ustlie/http.js'
export default {
  data() {
    return {};
  },
  created() {
    http.http()
  },
};
</script>

