import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import less from 'less'
import *as echarts from 'echarts'

import http from "@/ustlie/http.js"
//缩略图加预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 
  'inline': true, //启用lnline模式
  'button': true, //显示右上角关闭按钮
  'navbar': true, //显示缩略图导航
  'title': true, //显示当前图片的标题
  'toolbar': true, //显示工具栏
  'tooltip': true, //显示缩放百分比
  'movable': true, //显示可移动
  'zoomable': true, //图片是否可缩放
  'rotatable': true, //图片是否可旋转
  'scalable': true, //图片是否可翻转
  'transition': true, //使用css3过度
  'fullscreen': true, //播放时是否全屏
  'keyboard': true, //是否支持键盘
  'url': 'data-source' //设置大图片的url
  }
})

//图标
Vue.prototype.$echarts=echarts
//axios数据请求
Vue.prototype.$http=axios
axios.defaults.baseURL='https://ruraltofar.com/'
Vue.config.productionTip = false
Vue.use(ElementUI).use(Vant).use(less).use(http)


Vue.config.productionTip = false

router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title=to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
